import dynamic from 'next/dynamic'
import NextHead from 'next/head'
import { useEffect } from 'react'

import { Spinner } from '@wartek-id/spinner'

import { Navbar } from 'app/LandingV2Page/components/Navbar'
import { Hero } from 'app/LandingV2Page/components/Hero'
import { ContentPlaylists } from 'app/LandingV2Page/components/ContentPlaylists'
import { useSETrackEvent } from './hooks/useSETrackEvent'
import { Inspiration } from './components/Inspiration'

const BelowTheFoldSection = dynamic<{
  isKinerjaEnabled?: boolean
}>(
  () =>
    import('app/LandingV2Page/components/BelowTheFoldSection').then(
      (mod) => mod.BelowTheFoldSection
    ),
  {
    loading: () => (
      <div className="w-full bg-[#F2F8FE] flex justify-center py-8">
        <Spinner />
      </div>
    ),
    ssr: false,
  }
)

interface ILandingV2AppProps {
  isKinerjaEnabled: boolean
}
const LandingV2App = ({ isKinerjaEnabled }: ILandingV2AppProps) => {
  const { SETrackEventLandingPage } = useSETrackEvent()
  useEffect(() => {
    SETrackEventLandingPage('LANDING_PAGE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <NextHead>
        <title>Ruang GTK</title>
        <meta
          name="description"
          content="Temukan beragam bahan ajar, buku teks, modul ajar, paket soal asesmen, serta program pelatihan bagi guru, kepala sekolah, dan tenaga kependidikan."
        />
      </NextHead>

      <div className="bg-white text-type-basic-dark scroll-smooth">
        <Navbar clickTracker={SETrackEventLandingPage} />
        <Hero clickTracker={SETrackEventLandingPage} />
        <Inspiration />
        <ContentPlaylists />
        <BelowTheFoldSection isKinerjaEnabled={isKinerjaEnabled} />
      </div>
    </>
  )
}

export default LandingV2App
