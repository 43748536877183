import React from 'react'
import { useRouter } from 'next/router'

import { useGATrackEvent } from 'app/LandingV2Page/hooks/useGATrackEvent'
import { useSession } from 'app/LandingV2Page/hooks/useSession'
import LandingV2App from 'app/LandingV2Page'
import { isToggleEnabled } from 'configs/toggle-feature'
import { checkDateTimeServer } from 'app/PemetaanKompetensiPage/utils'
import { currentDateTimeServerContext } from 'utils/hooks/useCurrentDateTimeServerContext'

export async function getServerSideProps() {
  const isKinerjaEnabled = await isToggleEnabled('kinerja-enabled')
  const isRuangGtkEnabled = await isToggleEnabled('ruang-gtk-enabled')

  const currentDateTimeServer = await checkDateTimeServer()
  return {
    props: {
      currentDateTimeServer,
      isKinerjaEnabled,
      isRuangGtkEnabled,
    },
  }
}

const SessionRedirect = ({ children, isRuangGtkEnabled }) => {
  const router = useRouter()
  const { hasSession } = useSession()
  const redirectsToHome = isRuangGtkEnabled || hasSession

  React.useEffect(() => {
    if (redirectsToHome) {
      router.replace({
        pathname: '/home',
        query: {
          from: 'landing',
        },
      })
    }
  }, [redirectsToHome, router])

  if (redirectsToHome) {
    return null
  }

  return children
}

export const LandingPage = ({
  currentDateTimeServer,
  isKinerjaEnabled,
  isRuangGtkEnabled,
}) => {
  const { GATrackEventLandingPage } = useGATrackEvent()

  React.useEffect(() => {
    GATrackEventLandingPage({
      activityType: 'OPEN_HOMEPAGE',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  return (
    <SessionRedirect isRuangGtkEnabled={isRuangGtkEnabled}>
      <currentDateTimeServerContext.Provider value={currentDateTimeServer}>
        <LandingV2App isKinerjaEnabled={isKinerjaEnabled} />
      </currentDateTimeServerContext.Provider>
    </SessionRedirect>
  )
}

// eslint-disable-next-line react/display-name
LandingPage.Layout = ({ children }) => <>{children}</>

export default LandingPage
